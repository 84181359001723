<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 class="mb-0">كل الإشعارات</h2>
      <button class="btn btn-primary" @click="markAllAsRead">
        قراءة كل الإشعارات
      </button>
    </div>
    <ul v-if="notifications.length" class="list-group">
      <li v-for="notification in notifications" :key="notification.id" :class="[
        'notification-item list-group-item',
        { 'notification-unread': notification.is_read == 0 },
      ]">
        <b-media>
          <template #aside>
            <feather-icon
                :icon="getAvatarSrc(notification.type)"
                size="40"
              />
          </template>
          <div class="media-body text-right ">
            <p class="media-heading">
              <span class="font-weight-bolder">{{ notification.title }}</span>
              <small class="text-muted float-left">{{ formatDate(notification.created_at) }}</small>
            </p>
            <small class="notification-text">{{ notification.message }}</small>
          </div>
            <div class="mt-2 mark-read-btn">
              <a :href="notification.link" class="text-primary" target="_blank" rel="noopener noreferrer">
                عرض
              </a>
              <button class="btn btn-sm btn-link text-primary p-0" @click="markAsRead(notification)"
                v-if="!notification.is_read">
                تعيين كمقروء
              </button>
            </div>
        </b-media>
      </li>
    </ul>
    <p v-else class="text-center text-muted">لا يوجد إشعارات</p>
  </div>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api';
import { BMedia, BAvatar } from 'bootstrap-vue';
import store from '@/store';
import notificationStore from './notificationStore';

export default {
  components: {
    BMedia,
    BAvatar,
  },
  setup() {
    const NOTIFICATION_APP_STORE_MODULE_NAME = 'app-notification';

    // Register the notification store module
    if (!store.hasModule(NOTIFICATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(NOTIFICATION_APP_STORE_MODULE_NAME, notificationStore);
    }

    // Unregister the module on unmount
    onUnmounted(() => {
      if (store.hasModule(NOTIFICATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(NOTIFICATION_APP_STORE_MODULE_NAME);
      }
    });

    const notifications = ref([]);
    const placeholderAvatar = 'https://via.placeholder.com/40';

    // Fetch notifications
    store
      .dispatch('app-notification/fetchNotifications')
      .then((res) => {
        notifications.value = res.data.notifications;
      })
      .catch((error) => {
        console.error('Error fetching notifications:', error);
      });

    // Mark individual notification as read
    const markAsRead = (notification) => {
      store
        .dispatch('app-notification/markNotificationAsRead', notification.id)
        .then(() => {
          notification.is_read = 1;
        })
        .catch((error) => {
          console.error('Error marking notification as read:', error);
        });
    };

    // Mark all notifications as read
    const markAllAsRead = () => {
      store
        .dispatch('app-notification/markAllNotificationsAsRead')
        .then(() => {
          notifications.value.forEach((notification) => {
            notification.is_read = 1;
          });
        })
        .catch((error) => {
          console.error('Error marking all notifications as read:', error);
        });
    };

    // Get the avatar src based on notification type
    const getAvatarSrc = (type) => {
      switch (type) {
        case 'evaluation_assigned':
        return "AlertCircleIcon";
        case 'evaluation_completed':
          return "CheckCircleIcon";
        case 'user_mentioned':
        return "UserIcon";
        default:
        return "UserIcon";
      };
    }

    // Format the notification date
    const formatDate = (date) => {
      return new Date(date).toLocaleDateString('ar-EG', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    };

    return {
      notifications,
      markAsRead,
      markAllAsRead,
      formatDate,
      getAvatarSrc,
      placeholderAvatar,
    };
  },
};
</script>

<style scoped>
.notification-list {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eaeaea;
  transition: background-color 0.3s;
}

.notification-item.notification-unread {
  background-color: #f0f8ff;
  /* Light blue background for unread notifications */
}

.media-heading {
  font-weight: bold;
  margin-bottom: 0;
}

.notification-text {
  color: #666;
}

.btn-link {
  margin-left: 10px;
}

/* Mark read button positioning */
@media (min-width: 760px) {
  .mark-read-btn {
    position: absolute;
    right: 10%; /* Adjust this value as needed */
    top: 20%; /* Adjust this value as needed */
    transform: translateY(-50%);
  }
}
</style>
